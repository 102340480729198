<script>
    import { _ } from 'svelte-i18n'
    import { fly, slide } from 'svelte/transition'
    import { push as navigateTo } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import {
        postManagedLogo,
        putManaged,
        putManagedLogo,
        getManagedLogos,
    } from '$utils/api.js'
    import {
        DEFAULT_MANAGED_LOGO,
        DEFAULT_PERSONAL_LOGO,
        RECOMMENDED_MANAGED_LOGO_SIZE,
        DEFAULT_MANAGED_LOGO_NAME,
        SLIDE_ANIM_MS,
    } from '$src/constants.js'
    import * as sort from '$utils/sort.js'
    import ChooseManagedLogoButton from './button/ChooseManagedLogoButton.svelte'
    import InfoIcon from './icon/InfoIcon.svelte'
    import SpinnerIcon from './icon/SpinnerIcon.svelte'
    import UploadIcon from './icon/UploadIcon.svelte'

    let { states = $bindable({}), managedLogos = $bindable(null) } = $props()

    let uploadedLogos = $state([])

    const managed = $derived(
        global.data.chooseManagedLogo || global.data.chooseWhoManages,
    )
    const managedId = $derived(
        global.data.chooseWhoManages?.id || global.data.chooseManagedLogo?.id,
    )

    let selectManagedLogoAjax = $state(false)
    const selectManagedLogo = async () => {
        selectManagedLogoAjax = true
        const res = await putManaged(managedId, {
            managedBy: 'org',
        })
        if (!res?.error) {
            managedLogos = await getManagedLogos(
                global.data.chooseWhoManages.id,
            )
            if (!managedLogos?.error) {
                states.PICK_IMAGE = true
                selectManagedLogoAjax = false
            }
        }
    }

    let selectManagedSelfAjax = $state(false)
    const selectManagedSelf = async () => {
        selectManagedSelfAjax = true
        await putManaged(managedId, {
            managedBy: 'self',
        })
        global.data = {} //data will be refetched at root
        return navigateTo('/')
    }

    let selectManagedOrgAjax = $state(null)
    const selectManagedOrg = async (logo) => {
        selectManagedOrgAjax = logo.name
        const res = await putManagedLogo(managedId, {
            name: logo.name,
            mimeType: logo.mimeType,
        })
        if (!res.error) {
            global.data = {} //data will be refetched at root
            return navigateTo('/')
        }
    }

    const acceptedLogoTypes = [
        '.png',
        '.gif',
        '.jpg',
        '.jpeg',
        '.webp',
        '.apng',
    ]
    function handleLogoDrop(e) {
        e.preventDefault()
        if (!e.dataTransfer) return null
        const fileName = e.dataTransfer.files[0].name
        const fileExt = fileName.split('.').pop()
        if (!acceptedLogoTypes.includes(`.${fileExt}`)) {
            notification.show(
                `Please upload a valid image format (${acceptedLogoTypes.join(',')})`,
                'error',
            )
            document.getElementById('logo-upload-input-ref').value = ''
            return
        }
        handleLogoUpload(e.dataTransfer.files)
    }

    let logoUploadAjax = $state(false)
    async function handleLogoUpload(e) {
        const file = e.target?.files?.[0] || e[0]
        if (!file) return
        if (file.size > 1048576) {
            // greater than 1 mb
            notification.show(
                $_('Please upload a logo that is less than 1 MB'),
                'error',
            )
            document.getElementById('logo-upload-input-ref').value = ''
            return
        }
        logoUploadAjax = true
        const formDataObj = new FormData()
        formDataObj.append('file', file)
        const logo = await postManagedLogo(managedId, formDataObj)
        logo.originalName = file.name
        uploadedLogos = [...uploadedLogos, logo]
        logoUploadAjax = false
        document.getElementById('logo-upload-input-ref').value = ''
    }
</script>

{#if states.PICK_IMAGE}
    <div in:fly={{ y: 10, duration: SLIDE_ANIM_MS }}>
        <h1 class="text-lg">{$_('Select the logo to be used for')}</h1>
        <h1 data-test="domain" class="px-4 text-lg font-semibold">
            {managed?.domain}
        </h1>
        <div class="px-indent my-6 space-y-5">
            {#if Array.isArray(managedLogos) && managedLogos.length}
                <div data-test="discovered-logos">
                    <span class="text-sm font-medium">
                        {#if managedLogos.length > 1}
                            {$_('We found {count} logos', {
                                values: { count: managedLogos.length },
                            })}
                        {:else}
                            {$_('We found 1 logo')}
                        {/if}
                    </span>
                    <div class="mt-1 space-y-3">
                        {#each [...managedLogos].sort(sort.managedLogos) as logo}
                            <ChooseManagedLogoButton
                                loading={selectManagedOrgAjax === logo.name}
                                disabled={selectManagedOrgAjax === logo.name}
                                logo={logo.url}
                                mimeType={logo.mimeType}
                                fileName={logo.originalName}
                                onclick={() => selectManagedOrg(logo)}
                                size={{
                                    width: logo.width,
                                    height: logo.height,
                                }}
                            />
                        {/each}
                    </div>
                </div>
            {/if}
            <div data-test="default-logo">
                <span class="text-sm font-medium"
                    >{$_('Default account logo')}</span
                >
                <div class="mt-1 space-y-3">
                    <ChooseManagedLogoButton
                        logo={DEFAULT_MANAGED_LOGO}
                        loading={selectManagedOrgAjax ===
                            DEFAULT_MANAGED_LOGO_NAME}
                        disabled={selectManagedOrgAjax ===
                            DEFAULT_MANAGED_LOGO_NAME}
                        fileName={DEFAULT_MANAGED_LOGO_NAME}
                        size={{
                            width: RECOMMENDED_MANAGED_LOGO_SIZE,
                            height: RECOMMENDED_MANAGED_LOGO_SIZE,
                        }}
                        onclick={() =>
                            selectManagedOrg({
                                name: DEFAULT_MANAGED_LOGO_NAME,
                                mimeType: 'image/png',
                            })}
                    />
                </div>
            </div>
            {#if uploadedLogos?.length}
                <ul
                    data-test="uploaded-logos"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <span class="text-sm font-medium"
                        >{$_('Uploaded from device')}</span
                    >
                    <div class="mt-1 space-y-3">
                        {#each [...uploadedLogos].sort(sort.managedLogos) as logo}
                            <li transition:slide={{ duration: SLIDE_ANIM_MS }}>
                                <ChooseManagedLogoButton
                                    logo={logo.url}
                                    loading={selectManagedOrgAjax === logo.name}
                                    disabled={selectManagedOrgAjax ===
                                        logo.name}
                                    fileName={logo.originalName}
                                    mimeType={logo.mimeType}
                                    onclick={() => selectManagedOrg(logo)}
                                    size={{
                                        width: logo.width,
                                        height: logo.height,
                                    }}
                                />
                            </li>
                        {/each}
                    </div>
                </ul>
            {/if}
            <div>
                <span class="text-sm font-medium"
                    >{$_('Upload from device')}</span
                >
                <button
                    ondragover={(e) => {
                        e.preventDefault()
                        e.target?.classList.add('border-2')
                    }}
                    ondragenter={(e) => {
                        e.preventDefault()
                        e.target?.classList.add('border-2')
                    }}
                    ondragleave={(e) => {
                        e.preventDefault()
                        e.target?.classList.remove('border-2')
                    }}
                    ondrop={(e) => {
                        handleLogoDrop(e)
                        e.target?.classList.remove('border-2')
                    }}
                    onclick={() => {
                        document.getElementById('logo-upload-input-ref').click()
                    }}
                    class="h-18 border-charcoal mt-1 flex w-full items-center justify-center space-x-2 rounded-md border border-dashed text-sm hover:border-2 dark:border-[#d4d4d4]"
                    disabled={logoUploadAjax}
                >
                    {#if logoUploadAjax}
                        <SpinnerIcon css="h-6 w-6" />
                    {:else}
                        <div
                            class="pointer-events-none flex items-center space-x-2"
                        >
                            <UploadIcon />
                            <span>{$_('Click to upload or drag & drop')}</span>
                        </div>
                    {/if}
                </button>
                <input
                    onchange={(e) => handleLogoUpload(e)}
                    id="logo-upload-input-ref"
                    accept={acceptedLogoTypes.join(', ')}
                    type="file"
                    name="logo"
                    hidden
                />
            </div>
        </div>
    </div>
{:else}
    {#if managed?.email}
        <h1 class="text-lg">{$_('You logged in with')}</h1>
        <h1 data-test="email" class="px-4 text-lg font-medium">
            {managed?.email}
        </h1>
    {/if}

    <div class="mt-6">
        <h1 class="text-lg">{$_('We need to know more about')}</h1>
        <h1
            data-test="domain"
            class="mb-8 mt-7 px-4 text-center text-3xl font-medium"
        >
            {managed?.domain}
        </h1>
    </div>

    <div class="my-6 flex flex-col space-y-3">
        <button
            onclick={selectManagedLogo}
            class="btn-background h-18 inline-flex items-center px-4 text-left text-lg"
            disabled={selectManagedLogoAjax}
            data-test="managed-org-btn"
        >
            {#if selectManagedLogoAjax}
                <SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
            {:else}
                <span class="managed-rounded-square-lg">
                    <img
                        src={DEFAULT_MANAGED_LOGO}
                        alt="logo"
                        class="mt-0 max-h-[36px] w-9 object-contain"
                    />
                </span>
                <span class="ml-4">
                    {$_('This is an organization account')}
                </span>
            {/if}
        </button>
        <button
            onclick={selectManagedSelf}
            class="btn-background h-18 inline-flex items-center px-4 text-left text-lg"
            data-test="managed-personally-btn"
            disabled={selectManagedSelfAjax}
        >
            {#if selectManagedSelfAjax}
                <SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
            {:else}
                <span
                    class="managed-rounded-square-lg flex items-center justify-center bg-transparent"
                >
                    <img
                        src={DEFAULT_PERSONAL_LOGO}
                        alt="logo"
                        class="mt-0 max-h-[36px] w-9 object-contain invert"
                    />
                </span>
                <span class="ml-4">
                    {$_('This is a personal account')}
                </span>
            {/if}
        </button>
    </div>
{/if}
<p class="flex items-start text-sm opacity-80">
    <InfoIcon css="w-4.5 h-4.5" />
    <!-- TBD i18n -->
    <span class="ml-2 italic"
        >Only admins of<br /><span class="px-indent not-italic"
            >{managed?.domain}</span
        ><br />can update this setting later</span
    >
</p>
